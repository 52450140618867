import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { getArticleById } from '../../services/api';
import { Box, Typography, Card, CardMedia, CardContent, Accordion, AccordionSummary, AccordionDetails, CircularProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './ArticlePage.css';
import Breadcrumb from "../Breadcrumbs/Breadcrumbs";

function ArticlePage() {
    const { articleId } = useParams();
    const [article, setArticle] = useState(null);
    const [Markdown, setMarkdown] = useState(null);

    useEffect(() => {
        const fetchArticle = async () => {
            const fetchedArticle = await getArticleById(articleId);
            setArticle(fetchedArticle);
        };

        fetchArticle();
    }, [articleId]);

    useEffect(() => {
        import('react-markdown').then(module => {
            setMarkdown(() => module.default);
        });
    }, []);

    // Scroll to the top of the page on component mount
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (!article || !Markdown) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress size={100}/>
            </Box>
        );
    }

    const seo = article.seo_values || {};

    const renderSEOTags = () => {
        return Object.keys(seo).flatMap(key => {
            if (key === 'title') {
                return <title key={key}>{seo[key]}</title>;
            } else if (key === 'og') {
                return Object.keys(seo[key]).map(ogKey => {
                    return <meta key={ogKey} property={`og:${ogKey}`} content={seo[key][ogKey]} />;
                });
            } else if (Array.isArray(seo[key])) {
                return <meta key={key} name={key} content={seo[key].join(", ")} />;
            } else {
                return <meta key={key} name={key} content={seo[key]} />;
            }
        });
    };

    return (
        <Box className="article-container">
            <Breadcrumb/>
            <Helmet>{renderSEOTags()}</Helmet>
            <Typography variant="h3" className="article-title">
                {Markdown && <Markdown>{article.title}</Markdown>}
            </Typography>
            {article.image_link && (
                <Card>
                    <CardMedia
                        component="img"
                        image={article.image_link}
                        alt={article.title}
                    />
                </Card>
            )}
            <CardContent>
                <Typography variant="body1" className="article-content">
                    {Markdown && <Markdown>{article.content}</Markdown>}
                </Typography>
                {/* Example: <AdComponent /> */}
            </CardContent>
            {article.faqs && (
                <Box className="article-faqs">
                    <Typography variant="h6">FAQs</Typography>
                    {article.faqs.map((faq, index) => (
                        <Accordion key={index}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>{faq.q}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>{faq.a}</Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Box>
            )}
            {/* Additional components like related articles or comments */}
        </Box>
    );
}

export default ArticlePage;
