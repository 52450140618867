    import React, { useState, useEffect } from 'react';
    import Slider from 'react-slick';
    import { getArticles } from "../../services/api";
    import ArticleSliderItem from "../ArticleSliderItem/ArticleSliderItem";

    function ArticleSlider() {
        const [articles, setArticles] = useState([]);
        const articlesPerPage = 20; // Increase to ensure you have enough articles to cycle through
        const from = 5;

        useEffect(() => {
            const fetchArticles = async () => {
                const fetchedArticles = await getArticles(from, from + articlesPerPage);
                // One-liner shuffle using sort and Math.random
                fetchedArticles.sort(() => 0.5 - Math.random());

                setArticles(fetchedArticles);
            };

            fetchArticles();
        }, [from, articlesPerPage]);

        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 5, // Default for larger screens
            slidesToScroll: 5, // Default scroll for larger screens
            autoplay: true,
            autoplaySpeed: 4000,
            responsive: [
                {
                    breakpoint: 1800, // For large desktops
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4
                    }
                },
                {
                    breakpoint: 1200, // For large desktops
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 1024, // For smaller desktops and laptops
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 768, // For tablets
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480, // For mobile devices
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        return (
            <Slider {...settings} style={{ marginTop: '40px' }}>
                {articles.map((article) => (
                    <div key={article.id}>
                        <ArticleSliderItem article={article}/>
                    </div>
                ))}
            </Slider>
        );
    }

    export default ArticleSlider;
