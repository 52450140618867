// services/api.js
import axios from 'axios';
import rateLimit from 'axios-rate-limit';

const BASE_URL = 'https://autoweb-production.up.railway.app';

// Create a rate-limited axios instance
// maxRequests: maximum number of requests to be sent in a given period
// perMilliseconds: the time period in milliseconds for maxRequests
const http = rateLimit(axios.create(), { maxRequests: 5, perMilliseconds: 1000 });

export async function getArticles(paginationFrom = 0, paginationTo = 5) {
    try {
        const response = await http.get(`${BASE_URL}/get_articles_with_pagination`, {
            params: { pagination_from: paginationFrom, pagination_to: paginationTo }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching articles', error);
        return [];
    }
}

export async function getArticleById(articleId) {
    try {
        const response = await http.get(`${BASE_URL}/get_article_by_id/${articleId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching article', error);
        return null;
    }
}
