import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom'; // Correct import location
import ArticleList from './components/ArticleList/ArticleList';
import ArticlePage from './components/ArticlePage/ArticlePage';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import { Container, Box } from '@mui/material';
import './App.css';
import ArticleSlider from "./components/ArticleSlider/ArticleSlider";

function App({ location, context }) {
    return (
        <Router location={location} context={context}>
            <Box className="app-container">
                <Header />
                <Routes>
                    <Route path="/" element={
                        <>
                            <ArticleSlider />
                            <Container maxWidth="lg" component="main" className="main-content">
                                <ArticleList />
                            </Container>
                        </>
                    } />
                    <Route path="/articles" element={
                        <Container maxWidth="lg" component="main" className="main-content">
                            <ArticleList />
                        </Container>
                    } />
                    <Route path="/articles/:articleId/:articleTitle" element={
                        <Container maxWidth="lg" component="main" className="main-content">
                            <ArticlePage />
                        </Container>
                    } />
                </Routes>
                {/*<Footer />*/}
            </Box>
        </Router>
    );
}

export default App;
