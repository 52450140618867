import React from 'react';
import { AppBar, Toolbar, Typography, Box } from '@mui/material';
import './Header.css';

function Header() {
    return (
        <div className="root-header">
            <AppBar position="static">
                <Toolbar>
                    <Box className="header-content" sx={{ width: '100%', textAlign: 'center' }}>
                        <Typography variant="h3" component="h1" className="header-title" sx={{
                            fontSize: { xs: '2rem', sm: '2.5rem', md: '4rem' }  // Responsive font sizes
                        }}>
                            UnderGroundCrowd
                        </Typography>
                        <Typography variant="h6" className="header-tagline" sx={{
                            fontSize: { xs: '0.8rem', sm: '1rem', md: '1.5rem' }  // Responsive font sizes
                        }}>
                            Your Pulse on Global News, As It Happens.
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default Header;
