import React, { useState, useEffect } from 'react';
import ArticleItem from '../ArticleItem/ArticleItem';
import { getArticles } from '../../services/api';
import { Box, Card, CardActionArea, CircularProgress } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import './ArticleList.css';

function ArticleList() {
    const [articles, setArticles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true); // Track if there are more articles to load
    const articlesPerPage = 5;
    const { ref, inView } = useInView();

    useEffect(() => {
        // Only fetch articles if not currently loading and if there are more articles to load
        const fetchArticles = async () => {
            if (!isLoading && hasMore) {
                setIsLoading(true);
                const from = articles.length; // Calculate 'from' based on the current articles length
                const newArticles = await getArticles(from, from + articlesPerPage);
                setArticles(prev => [...prev, ...newArticles]);
                setIsLoading(false);
                // Check if the fetched articles are less than expected, indicating no more articles
                setHasMore(newArticles.length === articlesPerPage);
            }
        };

        if (inView && hasMore) {
            fetchArticles();
        }
    }, [inView, hasMore]); // Depend on inView and hasMore to control fetching

    // Show loading indicator at the bottom instead of a full-page loader
    return (
        <Box className="article-list-container">
            {articles.map(article => (
                <Card key={article.id} className="article-card">
                    <CardActionArea component={Link} to={`/articles/${article.id}`}>
                        <ArticleItem article={article} />
                    </CardActionArea>
                </Card>
            ))}
            <div ref={ref} />
            {isLoading && (
                <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                    <CircularProgress size={100}/>
                </Box>
            )}
        </Box>
    );
}

export default ArticleList;
