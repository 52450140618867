import React, { useState, useEffect } from 'react';
import { CardMedia, Typography, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

function ArticleItem({ article }) {
    const { id, title, content, image_link } = article;
    const articleSlug = title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
    const articlePath = `/articles/${id}/${articleSlug}`;
    const excerpt = content.split(' ').slice(0, 70).join(' ') + "...";

    const [Markdown, setMarkdown] = useState(null);

    useEffect(() => {
        import('react-markdown').then(module => {
            setMarkdown(() => module.default);
        });
    }, []);

    if (!Markdown) {
        return <p>Loading...</p>; // Or any other placeholder
    }

    return (
        <Box
            component={RouterLink}
            to={articlePath}
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                width: '100%',
                alignItems: { xs: 'center', md: 'flex-start' },
                textDecoration: 'none',
                color: '#e3e2e2',
                borderRadius: '8px',
                transition: 'background-color 0.3s ease',
                '&:hover': {
                    backgroundColor: '#545353',
                },
            }}
        >
            {image_link && (
                <Box sx={{
                    margin: 2,
                    padding: '8px',
                    display: 'inline-flex',
                    width: { xs: '100%', sm: 'auto' },
                }}>
                    <CardMedia
                        component="img"
                        sx={{
                            height: { xs: 150, sm: 200 },
                            width: { xs: '100%', sm: 200 },
                            objectFit: 'cover',
                            borderRadius: 5
                        }}
                        image={image_link}
                        alt={title}
                    />
                </Box>
            )}
            <Box sx={{
                flexGrow: 1,
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: { xs: 'center', md: 'left' },
            }}>
                <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
                    <Markdown>{title}</Markdown>
                </Typography>
                <Typography variant="body1" color="textSecondary" sx={{ color: '#c4c2c2' }}>
                    <Markdown>{excerpt}</Markdown>
                </Typography>
            </Box>
        </Box>
    );
}

export default ArticleItem;
