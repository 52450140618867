// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
    ReactDOM.hydrateRoot(
        rootElement,
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
} else {
    // Potentially handle client-side only rendering or display a message
    const root = ReactDOM.createRoot(rootElement);
    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
}
