import React from 'react';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import './Breadcrumbs.css';
import { formatBreadcrumb } from "../../utils/helpers"; // Assuming this formats text for display

const Breadcrumb = () => {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter(x => x);

    // Filter out numeric path segments if they represent IDs (adjust logic as needed)
    const filteredPathnames = pathnames.filter(path => isNaN(Number(path)));

    return (
        <Breadcrumbs color="#e3e2e2" separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            <Link component={RouterLink} underline="none" color="#4d4c4c" to="/">
                Home
            </Link>
            {filteredPathnames.map((value, index) => {
                const last = index === filteredPathnames.length - 1;
                const to = `/${filteredPathnames.slice(0, index + 1).join('/')}`;
                const breadcrumbText = formatBreadcrumb(value); // Format for readability

                return last ? (
                    <Typography key={to} color="#4d4c4c">
                        {breadcrumbText}
                    </Typography>
                ) : (
                    <Link component={RouterLink} underline="none" color="#4d4c4c" to={to} key={to}>
                        {breadcrumbText}
                    </Link>
                );
            })}
        </Breadcrumbs>
    );
};

export default Breadcrumb;
