import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CardMedia, Typography, Box } from '@mui/material';

function ArticleSliderItem({ article }) {
    const navigate = useNavigate();
    const { id, title, image_link } = article;

    // Function to handle click event and redirect to article page
    const handleClick = () => {
        navigate(`/articles/${id}/${title.replace(/\s+/g, '-')}`); // Assuming 'id' and 'title' as part of your article object
    };

    return (
        <Box
            onClick={handleClick}
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                width: '100%',
                textDecoration: 'none',
                color: '#e3e2e2',
                borderRadius: '8px',
                overflow: 'hidden',
                backgroundColor: '#000000',
                margin: '10px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
                cursor: 'pointer' // Adding cursor style for better user experience
            }}
        >
            {image_link && (
                <CardMedia
                    component="img"
                    sx={{
                        height: 100,
                        width: 100,
                        objectFit: 'cover',
                        borderRadius: '8px 0 0 8px',
                    }}
                    image={image_link}
                    alt={title}
                />
            )}
            <Typography variant="body2" component="div" sx={{
                textAlign: 'left',
                padding: '0 20px',
                fontSize: '0.85rem',
                color: '#e3e2e2',
                backgroundColor: '#000000',
                maxWidth: 'calc(100% - 225px)', // Adjusting width to allow space for the image
            }}>
                {title}
            </Typography>
        </Box>
    );
}

export default ArticleSliderItem;
